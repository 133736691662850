/* eslint-disable react/jsx-filename-extension */
import React from 'react';

import BrandIcon from 'parts/BrandIcon';
import Button from 'elements/Button';

export default function Footer() {
  return (
    <div className="bg-gray-50 border-t border-gray-200 pb-6">
      <div className="container flex-col mx-auto ">
        <div className="flex flex-col sm:flex-row mt-8 justify-center">
          <div className="w-1/3 flex-col ml-16 mr-8">
            <BrandIcon />
            <p className="w-full text-lg text-gray-400 font-light">
              Growing Your Business
              {' '}
              <br />
              Is Our Calling
            </p>
            <br />
            <Button href="https://ventrynet.com/tos.pdf" type="link" target="_blank" className="flex text-lg text-theme-purple font-light hover:underline" isExternal>
              Terms Of Service
            </Button>
            <Button href="https://ventrynet.com/privacy.pdf" type="link" target="_blank" className="flex text-lg text-theme-purple font-light hover:underline" isExternal>
              Privacy Policy
            </Button>
          </div>
          <div className="w-1/3 mt-0 ml-16 mr-0 sm:ml-0 sm:mr-5">
            <h1 className="text-lg text-theme-blue pt-4 pb-2">
              Office
            </h1>
            <p className="text-lg text-gray-400 font-light">
              info@ventrynet.com
            </p>
            <p className="text-lg text-gray-400 font-light">
              abuse@ventrynet.com
            </p>
            <p className="text-lg text-gray-400 font-light">
              +31 6 47 927 144 (also WhatsApp)
            </p>
            <p className="text-lg text-gray-400 font-light">
              CoC: 92774997
            </p>
            <p className="text-lg text-gray-400 font-light">
              VAT: NL004975605B66
            </p>
          </div>
          <div className="w-1/3 ml-16 sm:ml-0 mt-0">
            <h1 className="text-lg text-theme-blue pt-4 pb-2">
              Social
            </h1>
            <Button href="https://www.instagram.com/ventrynet/" type="link" target="_blank" className="flex text-lg text-gray-400 font-light hover:underline" isExternal>
              Instagram
            </Button>
            <Button href="https://discord.gg/cpFrekzwag" type="link" target="_blank" className="flex text-lg text-gray-400 font-light hover:underline" isExternal>
              Discord
            </Button>
            <Button href="https://github.com/ventrynet" type="link" target="_blank" className="flex text-lg text-gray-400 font-light hover:underline" isExternal>
              Github
            </Button>
          </div>
        </div>
        <div className="flex-col text-center mt-7">
          <p className="text-lg text-gray-400 font-light">
            Copyright 2024 - All rights reserved - VentryNet
          </p>
        </div>
      </div>
    </div>
  );
}
